/**
* init
**/
*,
*::before,
*::after {
	box-sizing: border-box;
}
body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  // -webkit-font-smoothing: antialiased;
  // font-smoothing: antialiased;
  text-rendering: auto;
  font-size: 100%;
  line-height: 1.0;
	font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Meiryo UI", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  color: #000;
  width: 100%;
}
a,
a:link,
a:visited,
a:hover,
a:active {
	text-decoration: none;
}
p,
li,
dt,
dd {
  font-weight: normal;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5 {
	font-weight: bold;
  margin: 0;
  padding: 0;
}
ul,
ol,
dl {
  margin: 0;
  padding: 0;
}
ul > li {
	list-style: none;
}
ol > li {
  list-style-position: inside;
}
table {
  border-collapse: collapse;
}
img {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

input[type=text],
textarea,
button,
select {
	vertical-align: middle; //	cursor: pointer;
	appearance: none;
}

input[type="submit"]:disabled {
	opacity: 0.65;
	cursor: default;
}
