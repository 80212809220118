@charset "utf-8";

@import "bourbon";
@import "sanitize";
@import "base";

/****************************************
		init
*****************************************/

/*  ▽▽  レスポンシブ  ▽▽  */
$width-sp: 567px;

@mixin mq-s {
	@media (max-width: ($width-sp)) {
		@content;
	}
}
/*  ▲▲  レスポンシブ  ▲▲  */

/*  ▽▽  fonts  ▽▽  */

// @import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

// @mixin fontsize($size: 24, $base: 16) {
// 	font-size: $size + px;
// 	font-size: ($size / $base) * 1rem;
// }

/*  ▲▲  fonts  ▲▲  */

/*  ▽▽  よく使うカラー  ▽▽  */
// $gold: #B8A536;
// $red: #D80B30;
// $navy: #000121;

/*  ▲▲  よく使うカラー  ▲▲  */

/*  ▽▽  mixin  ▽▽  */
//crearfix
@mixin clearfix {
	&::after {
		content:" ";
    display:block;
    clear:both;
	}
}
//hoverアニメーション
@mixin hoverOpacity($val: 0.7) {
	transition: opacity .2s;
	opacity: 1;
	&:hover {
		opacity: $val;
	}
	@include mq-s {
		transition: none;
		&:hover {
			opacity: 1;
		}
	}
}
/*  ▲▲  mixin  ▲▲  */

/**
* 準備中
**/
html,body{
  width: 100%;
}
.wrapper {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  // @include mq-s {
  //   width: 100%;
  // }
}
$refW: 1920;
$refH: 1080;
.contents {
  width: 100%;
  height: 100%;
  position: relative;
  // padding-top: 165 / $refH * 100%;
  padding-top: 80 / $refH * 100%;
  @include mq-s {
    padding-top: 240 / $refH * 100%;
  }
}
.contents__contact {
  width: 354 / $refW * 100%;
  max-width: 354px;
  min-width: 250px;
  margin: 0 auto;
  a {
    display: block;
  }
  img {
    width: 100%;
    height: auto;
  }
}
.contents__symbol {
  width: 700 / $refW * 100%;
  max-width: 700px;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  &::before {
    content: "";
    display: block;
    padding-top: 697 / 700 * 100%;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  @include mq-s {
    width: 90vw;
  }
}
